define([
    'angular'
], function () {
    'use strict';
    /**
     * Header Controller
     */
    var controllers = angular.module('app.controllers', ['ngSanitize'])
        .controller('HeaderController', HeaderController)
        .controller('MetaController', MetaController)
        .controller('MainController', MainController)
        .controller('NavController', ['$scope', '$rootScope', '$window', '$timeout', '$state','USER_SETTINGS', 'USER_MENU','menuDataService','GlobalService', 'settingFactory','workspaceFactory','AlertService', NavController])
        .controller('UserNotifController', ['notificationService', 'USER_SETTINGS', '$scope', UserNotifController])
        .directive('changeThemeEvent', ['$document', '$timeout', '$window', 'workspaceFactory', '$rootScope', function ($document, $timeout, $window, workspaceFactory, $rootScope) {
            return {
            restrict: 'A',
            link: function (scope, $element, attr) {
				/*Theme change method
				 * Removes previously stored theme
				 * Assing the new style theme
				 * refresh the less(generates the less).
				 */
				const changeTheme = function (theme, event) {
					if (event) {
						event.preventDefault();
					}
					$rootScope.$broadcast("currentTheme", theme);
					// $scope.userThemeSave(theme);

					// Create new link with selected theme.less
					var themeLess = theme + ".css";
					var link = document.createElement("link");
					link.rel = "stylesheet";
					link.type = "text/css";
					link.id = "currentTheme";
					link.href = themeLess;
					document.head.appendChild(link);

					var themeAppkit = "";
					var link2 = document.createElement("link");
					link2.rel = "stylesheet";
					link2.type = "text/css";
					link2.id = "appkit";
					if ("theme-white" != theme) {
						themeAppkit = "lib/gtw-elements/appkit-dark.css";
					} else {
						themeAppkit = "lib/gtw-elements/appkit-light.css";
					}
					link2.href = themeAppkit;
					document.head.prepend(link2);

					if ("theme-white" != theme) {
						$('link[href^="theme-white"]')?.remove();
						$(
							'link[href^="lib/gtw-elements/appkit-light"]'
						)?.remove();
					} else {
						$('link[href^="theme-black"]')?.remove();
						$(
							'link[href^="lib/gtw-elements/appkit-dark"]'
						)?.remove();
					}
				};

				$element.bind("change-theme", function (event) {
					console.log("theme-" + event);
					changeTheme("theme-" + event.originalEvent.detail);
					event.stopPropagation();
				});
			}
            };
        }])
        .directive('statementTrackerEvents', ['$rootScope','$uibModal', function ( $rootScope,$uibModal) {
            return {
            restrict: 'A',
            link: function (scope, $element, attr) {
                $element.bind('show-data-loss-confirmation', function(event) {
                    $rootScope.$emit('show-data-loss-confirmation',event.originalEvent.detail);

                });
                $element.bind('is-child-statement-edit', function(event) {
                    $rootScope.$emit('is-child-statement-edit',event.originalEvent.detail);

                });
            }
            };
        }])
        .directive('efileReviewAttachmentEvents', ['$rootScope','$uibModal', function ( $rootScope,$uibModal) {
            return {
            restrict: 'A',
            link: function (scope, $element, attr) {
                $element.bind('on-efile-review-attachment-upload', function(event,data) {
                    $rootScope.$emit('on-efile-review-attachment-upload',event.originalEvent.detail);

                });
            }
            };
        }])
        .directive('s3FileUploaderEvents', ['$rootScope','$uibModal', function ( $rootScope,$uibModal) {
            return {
            restrict: 'A',
            link: function (scope, $element, attr) {
                $element.bind('on-s3-file-upload', function(event,data) {
                    $rootScope.$emit('on-s3-file-upload',event.originalEvent.detail);

                });
            }
            };
        }])
        .directive('captureCustomEvents', ['$document', '$timeout', '$window', 'workspaceFactory', 'DataFiltersFactory', 'GlobalService', '$state', '$uibModal', '$rootScope', 'systemMessageFactory','$location',  'USER_MENU','detailedDiagnosticFacoty',
        function ($document, $timeout, $window, workspaceFactory, DataFiltersFactory, GlobalService, $state, $uibModal, $rootScope, systemMessageFactory,$location,USER_MENU,detailedDiagnosticFacoty) {
            return {
                restrict: 'A',
                link: function (scope, $element, attr) {
                    
                    $element.bind('load-current-screen', function(event) {
                        console.log('directives load-screens', event);
                        let obj = event.originalEvent.detail;
                        console.log({obj});
                        console.log($location.path());
                        $location.path(obj.url);
                        // .replace();                                         
                        workspaceFactory.loadLink("link", "{}",obj.screen.activity_key+'',obj.screen.screen_key+'');
                    })                        

                    $element.bind('load-screens', function(event) {
                        console.log('directives', event);
                        let screen = event.originalEvent.detail;
                        workspaceFactory.loadLink("link", "{}",screen.activity_key+'',screen.screen_key+'');
                            let breadcrumbArray = []; 
                        function buildBreadCrumbData(val) {
                            breadcrumbArray.push(val.label);
                            if (val.parent) {
                                buildBreadCrumbData(val.parent)
                            } else {
                                breadcrumbArray.reverse();
                            }
                        } 
                        buildBreadCrumbData(workspaceFactory.activeScreen);
                        console.log('breadcrumbArray',breadcrumbArray);
                        // $('gtw-header-ce').attr('bread-crumb', JSON.stringify(screen.breadcrumbArray));
                        $('gtw-header-ce').attr('bread-crumb', JSON.stringify(breadcrumbArray));
                        event.stopPropagation();                                          
                    });
            
                    $element.bind('load-modules', function(event) {
                        console.log('Modules Loaded', event);
                        let module_route = event.originalEvent.detail;
                        //$state.go(module_route, params, options);
                        $state.go(module_route);
                        //workspaceFactory.loadLink("link", "{}",screen.activityKey+'',screen.screenKey+'');
                        event.stopPropagation();
                    });

                    $element.bind('load-client', function(event) {
                        console.log('Client Loaded', event);
                        $rootScope.isBrowserBackButton = false;
                        let clientDetails = event.originalEvent.detail.selected[0];
                        GlobalService.setClientSelection(clientDetails);
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: 'app/templates/modals/client-confirm-action.html',
                            controller: ['$scope', '$stateParams', '$uibModalInstance',
                                function ($scope, $stateParams, $uibModalInstance) {
                                    var selected_client = GlobalService.getClientSelection();
                                    var audio = new Audio('assets/sound/voice_alert.mp3');
                                    audio.play();
                                    $scope.cancel = function () {
                                        $uibModalInstance.close();
                                    }

                                    $scope.confirm = function () {
                                        GlobalService.setClient(selected_client.client_key).then(function (data) {
                                            if (data.callSuccess === "1") {
                                                $rootScope.donotLogoutUser =true; // shouldn't logout on reload flag used in GlobalController
                                                location.reload();
                                            } else {
                                                failedToChangeClient();
                                            }
                                        });
                                      
                                        $scope.failedToChangeClient = function () {
                                            console.log("Failed to Change Client");
                                        }

                                        function failedToChangeClient() {
                                            var modalInstance = $uibModal.open({
                                                animation: true,
                                                templateUrl: 'app/templates/modals/client-load-failed.html',
                                                controller: ['$scope', '$stateParams', '$uibModalInstance',
                                                    function ($scope, $stateParams, $uibModalInstance) {
                                                        var audio = new Audio('assets/sound/voice_alert.mp3');
                                                        audio.play();
                                                        $scope.cancel = function () {
                                                            $uibModalInstance.close();
                                                        }

                                                        $scope.failedToChangeClient = function () {
                                                            $uibModalInstance.close();
                                                        }

                                                        $scope.$on('$destroy', function () {
                                                        });
                                                    }
                                                ],
                                                size: 'md',
                                                backdrop: 'static'
                                            });    
                                        }

                                        $uibModalInstance.close();
                                    }

                                    $scope.$on('$destroy', function () {
                                        ////////////console.log("ngReally Destroyed");
                                    });
                                }
                            ],
                            size: 'md',
                            backdrop: 'static'
                        });                       
                    });

                    $element.bind('set-taxYearScenario', function(event) {
                        console.log('Set Tax Year Scenario', event);
                        let _tax_year = event.originalEvent.detail;
                        GlobalService.inputs.tax_year.selected = _tax_year;
                        GlobalService.globalParams.tax_year = _tax_year.value;
		                GlobalService.globalParams.scenario = _tax_year.scenario;
                    });

                    $element.bind('logout-user', function(event) {
                        console.log('logout-user', event);
                        $rootScope.$broadcast('logoutCalled', false);
                        $state.go("loggedOut");
                    });

                    $element.bind('set-scenario', function(event) {
                        console.log('Set Scenario', event);
                        let _selectedScenario = event.originalEvent.detail.globalAppParams;
                        GlobalService.globalParams.scenario = _selectedScenario.scenarioKey;
                        GlobalService.globalParams.scenario_v1 = _selectedScenario.scenarioKey;
                        GlobalService.selected_scenario_desc = _selectedScenario.scenario_desc;
                        GlobalService.globalParams.issue_id = "";
                        GlobalService.inputs.tax_year.selected.value =   GlobalService.globalParams.tax_year;
                        GlobalService.inputs.tax_year.selected.scenario = GlobalService.globalParams.scenario;
                        GlobalService.inputs.tax_year.selected.scenario_desc = _selectedScenario.scenario_desc;
                        GlobalService.inputs.tax_year.selected.scenario_type_code = _selectedScenario.scenarioCode;
                        GlobalService.inputs.tax_year.selected.is_issue_key_enabled = _selectedScenario.ISSUE_ID_ENABLED;

                        GlobalService.inputs.tax_year.values = event.originalEvent.detail.taxYearList;
                        GlobalService.globalFilterValues = _selectedScenario;
                        console.log('USER_MENUUSER_MENU',USER_MENU);                       
                        $('gtw-navigation-ce').attr('channel-key', JSON.stringify(_selectedScenario));
                        $('gtw-ghost-console-ce').attr('global-params', JSON.stringify(GlobalService.globalParams));
                    });

                    /* load new left */
                    $element.bind('load-leftnav',function(event){
                        console.log('load-leftnav event',event);
                        console.log('USER_MENUUSER_MENU',USER_MENU);
                        let menu = [];
                        if(event.originalEvent.detail && event.originalEvent.detail.length>0){
                            menu =   _.filter(USER_MENU,function(i){
                                if(GlobalService.globalFilterValues && GlobalService.globalFilterValues.channel){
                                    if(i.id == GlobalService.globalFilterValues.channel){
                                        i.children = event.originalEvent.detail[0].children;
                                    }
                                }
                              
                            })  ;  
                            if(menu && menu.length>0){
                                USER_MENU = menu;
                            }                
                        } 
                       
                        console.log('after USER_MENUUSER_MENU',USER_MENU);
                    })
                    $element.bind('set-businesses', function(event) {
                        console.log('Set Businesses', event);
                        let filterss = event.originalEvent.detail;
                        GlobalService.setGlobalSelectedMes(filterss);
                    });
                    /* DataFiltersFactory.setGlobalMeVar(true);
                        DataFiltersFactory.onChange($scope.filterss); */

                    $element.bind('sync-businesses', function(event) {
                        console.log('Sync Businesses', event);
                        let filterss = event.originalEvent.detail;
                        if(event.originalEvent.detail && event.originalEvent.detail.selected.length>0){
                            filterss['fromGlobalME'] = true;
                        }else{
                            filterss['fromGlobalME'] = false;
                        }
                        filterss['param_name'] = 'parent_me_string';
                        DataFiltersFactory.setGlobalMeVar(true);
		                DataFiltersFactory.onChange(filterss);
                    });

                    $element.bind('global-params-changed', function(event) {
                        console.log('Global Params Changed', event);
                        console.log(GlobalService.globalParams);
                        $rootScope.$broadcast('GlobalService.globalParams: changed');
                    });

                    $element.bind('got-notifications', function(event) {
                        console.log('Got Notifications', event);
                        let notifDetails = event.originalEvent.detail;
                        if(notifDetails.onlyNotifMsg){
                            if((!_.isEqual(systemMessageFactory.NotifListData && systemMessageFactory.NotifListData.length, notifDetails.data.notif_list.length)) ||
                                (!_.isEqual(systemMessageFactory.selectedNotifData, notifDetails.selectedSysMsgData))){
                                    $rootScope.$broadcast('gotNotifMsg',false);
                                    systemMessageFactory.storeSelectedNotifData(notifDetails.data.notif_list[0], notifDetails.data.notif_list);
                                    scope.$applyAsync(function() {
                                        $rootScope.$broadcast('gotNotifMsg',true);
                                    })
                            }
                        }else{
                            if(notifDetails.emptyNotifData){
                                systemMessageFactory.storeSelectedNotifData({});
                            }
                            $rootScope.$broadcast('gotNotifMsg',false);
                        }
                    });

                    $element.bind('set-issue', function(event) {
                        console.log('Set Issue', event);
                        let issueDetails = event.originalEvent.detail;
                        if(issueDetails.setAllParams){
                            GlobalService.global_issues = issueDetails.data;
					        GlobalService.globalParams.issue_id = issueDetails.selectedIssueData.issue_id;
                            GlobalService.globalParams.issue_short_desc = issueDetails.selectedIssueData.issue_short_desc;
                        }else{
                            if(issueDetails.setOnlyId){
                                GlobalService.globalParams.issue_id = issueDetails.selectedIssueData.issue_id;
                                GlobalService.globalParams.issue_short_desc = issueDetails.selectedIssueData.issue_short_desc;
                            }else{
                                GlobalService.globalParams.issue_id = '';
                                GlobalService.globalParams.issue_short_desc = '';
                            }
                        }
                    });

                    $element.bind('load-application', function(event) {
                        console.log('Channel Changed', event);
                        let channelDetails = event.originalEvent.detail;
                        //$rootScope.selectedChannel = channelDetails;
                        //$('gtw-navigation-ce').attr('channel-key', channelDetails);
                        $('gtw-navigation-ce').attr('channel-key', JSON.stringify(channelDetails));
                    });

                    $element.bind('set-compare-job-id', function(event) {
                        console.log('Set Job Ids For Comparison', event);
                        let screen = event.originalEvent.detail;
                        //workspaceFactory.loadLink("link", "{}", screen.activity_key+'', screen.screen_key+'');
                        workspaceFactory.loadLink("link", "{}", 6327 +'', 11306 +'');
                        $rootScope.$broadcast('dataFilters:refreshGrid', {
                            "refresh": true
                        });
                    });
                    $element.bind('load-detail-dashboard',function(event){
                        console.log('load-dashborad-screen', event);
                       let screenObj = event.originalEvent.detail;
                         /* if(!_.isEqual(detailedDiagnosticFacoty.drillDownParams,screenObj.filterParams)){
                            GlobalService.setDetailDiagData({});
                        }
                        screenObj.filterParams['fromLoadLink'] = true;
                        detailedDiagnosticFacoty.setSeltedDrillDownFilterValues(screenObj.filterParams) */
                        workspaceFactory.loadLink("drillDown", "{}", screenObj.activity_key +'', screenObj.screen_key +'',screenObj.screenFilter,screenObj.gridFilters);
                       // $('gtw-detail-diagnostic-ce').attr('selected-drilldown-filter-params', JSON.stringify(screenObj.filterParams));

                    });
                    $element.bind('load-detail-grid'),function(event){
                        console.log('load-detail-grid', event);
                        let screen = event.originalEvent.detail;

                        if(!_.isEqual(detailedDiagnosticFacoty.drillDownParams,screenObj.filterParams)){
                            GlobalService.setDetailDiagData({});
                        }
                        detailedDiagnosticFacoty.setSeltedDrillDownFilterValues(screenObj.filterParams)
                        $rootScope.$broadcast('loadDetailDiag',  event.originalEvent.detail);                    
                    }
                    $element.bind('load-drill-down-screen',function(event){
                        let screenObj = event.originalEvent.detail;
                        workspaceFactory.loadLink("drillDown", "{}", screenObj.screenFilter.activity_key +'', screenObj.screenFilter.screen_key +'');


                    })

                    $element.bind('close-modal',function(event){
                        $rootScope.$broadcast('close-modal',  event.originalEvent.detail);                    
                    })

                }
            };
        }])

    HeaderController.$inject = [
        '$scope',
        '$rootScope',
        '$location',
        'workspaceFactory',
        'JsonObjectFactory',
        'SERVER_CONFIG',
        'GENERAL_CONFIG',
        'USER_SETTINGS',
        'systemMessageFactory',
        '$compile'
    ];

    MetaController.$inject = [
        '$location',
        'GENERAL_CONFIG',
        '$http',
        'USER_SETTINGS'
    ];

    MainController.$inject = ['$scope', '$rootScope', 'AlertService', '$http', 'GENERAL_CONFIG', 'UserFactory', 'Idle', 'IdleFactory', '$uibModal', 'SERVER_CONFIG', 'USER_SETTINGS', '$timeout', '$element',  '$window','$filter','$location', 'GlobalService', 'USER_MENU', 'ROUTES','$state'];

    function HeaderController($scope, $rootScope, $location, workspaceFactory, JsonObjectFactory, 
        SERVER_CONFIG, GENERAL_CONFIG, USER_SETTINGS,systemMessageFactory,$compile) {
        var vm = this;
        vm.isActive = isActive;
        vm.messageCount = 0;
        var counter = 0;
        
        vm.clientObj = USER_SETTINGS.user.clients;

        function isActive(route) {
            return route === $location.path();
        }
        vm.loadActivity = function () {
            workspaceFactory.loadActiveActivity();
        }

        var isMessageCountLoading = false,
            firstTime = true;
        vm.reloadMessage = false;
        vm.reloadTask = false;
        //Variable to check if getUnreadMessages is already polling
        $rootScope.isGetUnreadMessageActive; 
        /* APPLICATION NOTIFICATIONS  */
        vm.activeSysMsgData = [];
        vm.selectedSysMsgData = {};
        vm.showAlertNotif = false;
        vm.getSystemNotification = function () {
            var data = {
                sso_id: USER_SETTINGS.user.sso_id,
                client_key: USER_SETTINGS.user.client_key,
                user_type: USER_SETTINGS.user.client.user_type_key
            }
            systemMessageFactory.getSystemNotifByUser(GENERAL_CONFIG.admin_api_url + '/getSystemNotifBySso', {data}).then(function (data) {
                if (data && data.callSuccess == 1) {
                    vm.activeSysMsgData = data.notif_list;
                    if(data.notif_list && data.notif_list.length>0){
                        vm.selectedSysMsgData = data.notif_list[0];
                        data.notif_list[0].notif_msg_body = JSON.parse(data.notif_list[0].notif_msg_body);
                        
                        if((!_.isEqual(systemMessageFactory.NotifListData && systemMessageFactory.NotifListData.length, data.notif_list.length)) ||
                            (!_.isEqual(systemMessageFactory.selectedNotifData,vm.selectedSysMsgData))){
                                $rootScope.$broadcast('gotNotifMsg',false);
                            systemMessageFactory.storeSelectedNotifData(data.notif_list[0], data.notif_list);
                            $scope.$applyAsync(function() {
                                $rootScope.$broadcast('gotNotifMsg',true);
                            })
                       }
                    }else{
                        $rootScope.$broadcast('gotNotifMsg',false);
                    }
                  
                }
            });
        }
        function isJsonOrNot(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
        vm.selectSysNotif = function(selectedItem, index) {
            vm.selectedSysMsgData = selectedItem;
            vm.selectedSysMsgData.notif_msg_body = isJsonOrNot(selectedItem.notif_msg_body) ? JSON.parse(selectedItem.notif_msg_body) :selectedItem.notif_msg_body;
        }
        vm.notifCong = function(){
            if(vm.checkNotif == 1){
                var data = {
                    sys_notif_key : vm.selectedSysMsgData.notif_msg_key,
                    sso_id: USER_SETTINGS.user.sso_id,
                    client_key: USER_SETTINGS.user.client_key
                }
                systemMessageFactory.saveSystemDontShowMsg(data).then((response) =>{
                    if(response && response.callSuccess == 1){
                        if(vm.activeSysMsgData && vm.activeSysMsgData.length > 1) {
                            //Remove the notifications only active list is morethan one and sets the first notification from the active list, otherwise will remove the notification..
                            _.find(vm.activeSysMsgData, function(item) {
                                if(item && item.notif_msg_key == vm.selectedSysMsgData.notif_msg_key) {
                                    vm.activeSysMsgData.splice(item, 1);
                                }
                            })
                            vm.selectedSysMsgData = vm.activeSysMsgData[0];
                        } else {
                            vm.activeSysMsgData = [];
                            systemMessageFactory.storeSelectedNotifData({});
                            $rootScope.$broadcast('gotNotifMsg',false);
                        }
                    }
                    vm.checkNotif = 0;
                })
            }
        }
        vm.cancelNotifModal = function(){
            systemMessageFactory.storeSelectedNotifData({});
            $rootScope.$broadcast('gotNotifMsg',false);

        }
        /* to get the Unread Message count and Incompleted Tasks count  */
        vm.getUnreadMessages = function () {

            if (counter > Number(SERVER_CONFIG.configSettings.max_no_of_failed_atempts)) {
                return;
            }

            if (isMessageCountLoading)
                return;
            isMessageCountLoading = true;
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/getUnreadMesageCount', {}).then(function (data) {
                setTimeout(function () {
                    isMessageCountLoading = false;
                }, 5000);

                var messageCount = Number(data.totalUnreadMessageCount);
                var taskCount = Number(data.totalUnCompleteTaskCount);
                console.log("data>>>>>", data);

                if (data.unreadMessageCount === 'success') {
                    var messageCount = Number(data.totalUnreadMessageCount);
                    var taskCount = Number(data.totalUnCompleteTaskCount);
                    if(data == false){
                        /* $rootScope.isShowSystemUnAvilableMsg=true;
                        return; */
                        window.location.href = GENERAL_CONFIG.logout_idam_session;
                   }
                    if (firstTime) {
                        vm.messageCount = messageCount;
                        vm.taskCount = taskCount;
                        firstTime = false;
                    }

                    if (vm.messageCount !== messageCount) {
                        vm.reloadMessage = true;
                        vm.messageCount = messageCount;
                        // $rootScope.loadComments(true);
                        // vm.messageCount=messageCount;
                        // $rootScope.updateMessageCount(messageCount);
                    } else {
                        vm.reloadMessage = false;
                    }
                    /* starts added on 7/25/2019 */
                    if(vm.taskCount !== taskCount){
                        vm.reloadTask = true;
                        vm.taskCount = taskCount;
                    }else{
                        vm.reloadTask = false;
                    }
                    /* ends here*/
                } else {
                    counter++;
                }
            });
        }

        $rootScope.checkReloadComments = function () {
            if (vm.reloadMessage) {
                vm.reloadMessage = false;
                return true;
            }
            return false;
        };

        $rootScope.checkReloadTasks = function () {
            if (vm.reloadTask) {
                vm.reloadTask = false;
                return true;
            }
            return false;
        };
       /**************-----------loadComment && Tasks--------*******************/
        $rootScope.setReloadCommentsFlag = function (flag) {
            vm.reloadMessage = flag; ////new message only call the reload message
        }
        $rootScope.updateMessageCount = function (msgCnt) {
            if (msgCnt > 0 && !isNaN(msgCnt)) vm.messageCount = Number(msgCnt);
            else vm.messageCount = 0;
        }
        $rootScope.updateMessageCountPlus = function () {
            vm.messageCount = vm.messageCount + 1;
        }
        $rootScope.updateMessageCountMinus = function () {
            vm.messageCount = vm.messageCount - 1;
        }

         /**************-----------update the task count--7/25/2019-----*******************/
        $rootScope.updateTaskCount = function (taskCnt) {
            if (taskCnt > 0 && !isNaN(taskCnt)) vm.taskCount = Number(taskCnt);
            else vm.taskCount = 0;
        }


        /* call this method based on interval- it updates the message & task count */

        if ( !angular.isDefined($rootScope.isGetUnreadMessageActive) ) {
            $rootScope.isGetUnreadMessageActive = setInterval(function () {
                vm.getUnreadMessages();
                vm.getSystemNotification();
            }, Number(SERVER_CONFIG.configSettings.messagePullInterval));
        }

        vm.getUnreadMessages();
        vm.getSystemNotification();

        
        
    }


    function MetaController($location, GENERAL_CONFIG, $http, USER_SETTINGS) {
        var vm = this;
        vm.pageTitle = pageTitle;
        vm.getTheme = '';
        vm.lessURL = '';



        function pageTitle() {
            return makeTitle($location.path());
        }


        vm.getTheme = USER_SETTINGS.user.selected_theme

        // var theme = (vm.getTheme === null || vm.getTheme === '' || vm.getTheme === undefined) ? 'assets/less/theme-black.less' : 'assets/less/' + vm.getTheme + '.less';

        // if((vm.getTheme === null || vm.getTheme === '' || vm.getTheme === undefined)){
        //     var theme ='theme-black.css'
        // }else {
        //     // Create new link with selected theme.less
        //     var theme = vm.getTheme;
        //     var link = document.createElement('link');
        //     link.rel = "stylesheet";
        //     link.type = "text/css";
        //     link.id = "currentTheme";
        //     link.href = theme+".css";
        //     // fetch(theme+".css").then(function(){
        //         $('link[href^="theme-"]').remove();
        //         document.head.appendChild(link);
        //     // });
        // }

    }


    function makeTitle(_string) {

        _string = _string.replace("/", "");
        return _string.charAt(0).toUpperCase() + _string.slice(1);
    }



    function MainController($scope, $rootScope, AlertService, $http, GENERAL_CONFIG, UserFactory, Idle, IdleFactory, $uibModal, SERVER_CONFIG, USER_SETTINGS, $timeout, $element, $window, $filter,$location, GlobalService, USER_MENU, ROUTES,$state) {
        var vm = this;
        $scope.events = [];
        $scope.countdown = "";
        $scope.appBody = [];
        $scope.appBody = angular.element(document).find('body');

        $scope.baseURLs = {};
        $scope.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        $scope.baseURLs.api = GENERAL_CONFIG.base_url;
        $scope.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        $scope.saved_theme = USER_SETTINGS.user.selected_theme;
        $scope.selectedChannel = {};
        $scope.loading = true;
        $scope.breadcrumb = "";

        //Client, Channel, TaxYear and Scenario dropdown data for the Angular Navigation
        $scope.headerData = {
            globalParams : angular.copy(GlobalService.globalParams),
            clientData : {
                clients : GlobalService.getClients(),
                client : GlobalService.getClient()
            },
            channelData : [],
            taxYearScenarioData : angular.copy(GlobalService.inputs.tax_year),
            userSettingsData : angular.copy(USER_SETTINGS),
            serverConfigData : angular.copy(SERVER_CONFIG),
            generalConfigData : angular.copy(GENERAL_CONFIG)
        }

        var channels = [];
        _.forEach(USER_MENU, function(value, key) {
            var tempChannel = {
                value: value.id,
                text: value.label,
                selected: key == 0 ? true : false
            }
            channels.push(tempChannel);
            tempChannel = {};
        });
        $scope.headerData.channelData = channels;

        vm.tax_year = GlobalService.inputs.tax_year;
        vm.scenarios = vm.tax_year.selected.scenarios;
        GlobalService.selected_scenario_desc = vm.tax_year.selected.scenario_desc;

        ///////////// JUST TEST REMOVE /////////////////////////

        $scope.user = USER_SETTINGS.user;
        $scope.settings = SERVER_CONFIG.configSettings;
        /* used for systm notification */
        $scope.shownotifMsg = false;
        $scope.showHeaderNav = true;
        $rootScope.$on('gotNotifMsg',function(event,data){
            $scope.shownotifMsg = data;
        })

        $rootScope.$on('logoutCalled',function(event, data){
            $scope.showHeaderNav = data;
            sessionStorage.removeItem('Authorization');
        })

        console.log(" USER_SETTINGS.user ", USER_SETTINGS.user);
        console.log(" SERVER_CONFIG.configSettings ", SERVER_CONFIG.configSettings);

        // $scope.unloadParticles = function () {
        //     if (window["pJSDom"] instanceof Array && window["pJSDom"].length > 0) {
        //         window["pJSDom"][0].pJS.fn.vendors.destroypJS();
        //         window["pJSDom"] = [];
        //     }
        // };
        
        $scope.toggleVal = true;
        $scope.loading = false;
        
        // MOVE EDIT SETTINGS TO SERVICE
        $scope.toggleNavPanel = function () {

            var $icon = $element.find('.toggle-nav i');
            if ($rootScope.editedSettings.navPanel === 'closed') {
                $scope.toggleVal = true;
                $rootScope.editedSettings.navPanel = 'open';
                $icon.removeClass('fa-arrow-circle-right');
                $icon.addClass('fa-arrow-circle-left');
            } else {
                $scope.toggleVal = false;
                $rootScope.editedSettings.navPanel = 'closed';
                $icon.removeClass('fa-arrow-circle-left');
                $icon.addClass('fa-arrow-circle-right');

            }
            $rootScope.$broadcast("navPanel");

        }
        
        //onHover left panel method(menu expansion)..
        $scope.onHoverMethod = function() {
            if ($rootScope.editedSettings.navPanel === 'closed' && $scope.toggleVal === false) {
                $rootScope.editedSettings.navPanel = 'open';
                $rootScope.$broadcast("navPanel");
            }
        }

        //onHover leave left panel method(menu collapsion)..
        $scope.onHoverMethodLeave = function() {
            if($rootScope.editedSettings.navPanel === 'open' && $scope.toggleVal === false) {
                $rootScope.editedSettings.navPanel = 'closed';
                $rootScope.$broadcast("navPanel");
            }
        }

        /* 5/28/2019 */
        $scope.$on('collapseLeft', function (event, data) {
            $rootScope.editedSettings.navPanel = 'open';
            $scope.toggleNavPanel();
        })

        if (USER_SETTINGS.user) {
            $scope.user = USER_SETTINGS.user;
        } else {
            UserFactory.getUserSession().then(function (user) {
                $scope.user = user;
            });
        }
        /////////////////////////// START IDLE FUNCTIONS ////////////////////////////////

        $scope.started = false;
        //window.location = GENERAL_CONFIG.base_url.redirect_url;
        //var redirect_url;
        // This is my code what i am writting here


        var URLS = {
            logoutUser: GENERAL_CONFIG.base_url + "/logoutUser"
        }

        $scope.logoutCall = function () {
            return $http({
                method: "GET",
                url: URLS.logoutUser
            }).then(function (response) {
                sessionStorage.removeItem('Authorization');
            });
        };

        $scope.hardReload = function () {
            location.reload(true);
        }

        $window.onunload = function(event) {
            console.log('GLOBAL CONTROLLER : window onunload or beforeunload', event);
            if(!$rootScope.donotLogoutUser){
                $.ajax({
                    type: 'GET',
                    async: false,
                    url: URLS.logoutUser
                });
            }
        };



        function closeModals() {
            if ($scope.warning) {
                $scope.warning.close();
                $scope.warning = null;
            }

            if ($scope.timedout) {
                $scope.timedout.close();
                $scope.timedout = null;
            }
        }

        $scope.$on('IdleStart', function () {
            closeModals();
            $scope.warning = $uibModal.open({
                templateUrl: 'app/templates/modals/warning-dialog.html',
                windowClass: 'modal-warning',
                controller: ['$scope', '$stateParams', '$uibModalInstance', 'GENERAL_CONFIG',
                    function ($scope, $stateParams, $uibModalInstance, GENERAL_CONFIG) {
                        $scope.countdown = GENERAL_CONFIG.timeout;
                        $scope.timeOut = GENERAL_CONFIG.timeout;
                        var audio = new Audio('assets/sound/voice_alert.mp3');
                        audio.play();
                        $scope.$on('$destroy', function () {});
                    }
                ]
            });
        });

        $scope.$on('IdleEnd', function () {
            closeModals();
        });

        $scope.$on('IdleTimeout', function () {
            closeModals();
            $scope.logoutCall().then(()=>{
                $rootScope.$broadcast('logoutCalled', false);
                $state.go("loggedOut");
            })
        });

        $scope.$on('Keepalive', function () {
            //console.log("ping the server ");
            $scope.events.push({
                event: '$keepalive',
                date: new Date()
            });
            IdleFactory.keepAlive();
        });


        $scope.start = function () {
            closeModals();
            Idle.watch();
            Keepalive.start();

            $scope.started = true;
        };

        $scope.stop = function () {
            closeModals();
            Idle.unwatch();
            $scope.started = false;
        };


        $scope.showDebug = GENERAL_CONFIG.app_debug;
        $scope.serverConfig = SERVER_CONFIG;


        $rootScope.closeAlert = function (index) {
            AlertService.closeAlert(index);
        };


        let checkUrlAccess = () =>{
            let currentState ="";
            // Get the URL from browsers
            let path = $location.path();
            // Match state with url from ROUTES
            for(let state in ROUTES){
                if(path.indexOf(ROUTES[state].url) >= 0 ){
                    currentState = state;
                }
            }
            $scope.currentState = {"currentState":currentState};
        }
        checkUrlAccess();
    }

    function NavController($scope, $rootScope, $window, $timeout,$state, USER_SETTINGS, USER_MENU, menuDataService,  GlobalService, settingFactory, workspaceFactory, AlertService) {

        var vm = this;
        vm.selectedMeList = [];
        vm.selectedMeList.selected = [];
        vm.oldScenarioTypeCode = '';
        vm.selected_issue_id = '';
        // $scope.hideLeftNavFilters = [];

        // $scope.$on("hideFilters", function(event, val) {
        //     $scope.hideLeftNavFilters = val;
        // });

        $scope.getScrollHeight = function () {
            if(angular.element('.gt-nav').length>0){
                var top = angular.element('.gt-nav')[0].getBoundingClientRect().top;
                return $window.innerHeight - top;
            }
            return $window.innerHeight - 68;
        }

        vm.tax_year = GlobalService.inputs.tax_year;
        vm.scenarios = vm.tax_year.selected.scenarios;
        GlobalService.selected_scenario_desc = vm.tax_year.selected.scenario_desc;
        vm.issues = [];

        vm.getIssues = function() {
            GlobalService.getIssue(vm.tax_year.selected.value, vm.tax_year.selected.scenario).then(function(data) {
                if(data.data.callSuccess == 1) {
                    vm.issues = data.data.issue;
                    GlobalService.global_issues = data.data.issue;
                    if(vm.issues.length > 0){
                        GlobalService.globalParams.issue_id = vm.issues[0].issue_id;
                    } else {
                        GlobalService.globalParams.issue_id = '';
                    }
                    if(vm.issues && vm.issues.length > 0){
                        vm.selected_issue_id = vm.issues[0].issue_short_desc +' ('+GlobalService.globalParams.issue_id+')';
                    }else{
                        vm.selected_issue_id = GlobalService.globalParams.issue_id;
                    }
                    
                } else {
                    AlertService.add('error', 'Global level issue id list failed, please contact support');
                }
            });
        }
        vm.getIssues();

        vm.setTaxYear = function (_tax_year) {
            console.log("_tax_year :: ", _tax_year);
            GlobalService.inputs.tax_year.selected = _tax_year;
            GlobalService.globalParams.scenario = _tax_year.scenario;
            vm.scenarios = vm.tax_year.selected.scenarios;
            var defaultScenario = _.filter(vm.scenarios, {
                default_scenario: 'Y'
            });
            if (null != defaultScenario || defaultScenario != 'undefined' || defaultScenario.length > 0) {
                vm.setScenario(defaultScenario[0]);
            }else{
                $rootScope.$broadcast('GlobalService.globalParams: changed');
            }

            console.log("GlobalService.globalParams.scenario :: ", GlobalService.globalParams.scenario);
            vm.leftNavSelectedMe(); // used to get heirarchy list based on the tax year selection
            // vm.getIssues();
        };

        vm.setIssue = function (selectedIssue) {
            GlobalService.globalParams.issue_id = selectedIssue.issue_id;
            vm.selected_issue_id = selectedIssue.issue_short_desc +' ('+ selectedIssue.issue_id+')';
            console.log("GTW Global Issue Id set To::", GlobalService.globalParams.issue_id);
            vm.searchItem = '';
        }

        vm.issueSearchItem = function(event){
            event.stopPropagation();
        }
        
        // get heirarchy list api call
        vm.leftNavSelectedMe = function() {
            var params ={
                tax_year: vm.tax_year.selected.value,
                scenario: vm.tax_year.selected.scenario,
                jcd_key: GlobalService.globalParams.jcd_key==""? 250:GlobalService.globalParams.jcd_key
            }

            vm.selectedMeList.loading = true;

            GlobalService.getBizHeirarch(params).then(function(data) {
                if(data.data.callSuccess == 1) {
                    vm.selectedMeList.loading = false;
                    vm.selectedMeList.values = data.data.jsonObject;
                    GlobalService.setGlobalSelectedMes(vm.selectedMeList);
                } else {
                    AlertService.add('error', 'Global level ME seletions list failed, please contact support');
                }
            });
        }
        vm.leftNavSelectedMe();

        vm.setScenario = function (_selectedScenario) {
            GlobalService.globalParams.scenario = _selectedScenario.scenario;
            vm.tax_year.selected.scenario_desc = _selectedScenario.scenario_desc;
            vm.tax_year.selected.scenario = _selectedScenario.scenario;
            vm.tax_year.selected.scenario_type_code = _selectedScenario.scenario_type_code;
            GlobalService.selected_scenario_desc = _selectedScenario.scenario_desc;
            GlobalService.globalParams.issue_id = "";
            vm.getIssues();
            if(vm.oldScenarioTypeCode.length === 0 || (vm.oldScenarioTypeCode.length > 0 && vm.oldScenarioTypeCode !== _selectedScenario.scenario_type_code)) {
                vm.getUserMenu();
                vm.oldScenarioTypeCode = _selectedScenario.scenario_type_code ? _selectedScenario.scenario_type_code : '';
            }
            $rootScope.$broadcast('GlobalService.globalParams: changed');
            console.log("GTW Global Scenario Set To::", GlobalService.globalParams.scenario, " - ", _selectedScenario.scenario_desc, " - Tax Year ::", vm.tax_year.selected.value);
            vm.leftNavSelectedMe();
        }

        vm.getUserMenu = function() {
            GlobalService.getUserMenu(vm.tax_year.selected.label, vm.tax_year.selected.scenario, vm.tax_year.selected.scenario_type_code).then(function(data) {
                    var menuData =  data.data;
                    if(menuData.callSuccess === "0"){
                        $state.go("no-modules");
                        USER_MENU[0] = [];
                        workspaceFactory.activeScreen = {};
                        return ;
                    }
                    USER_MENU[0] = menuDataService.setData(menuData)[0];
                    setTimeout(function() {
                        if(USER_MENU && USER_MENU.length > 0) {
                            var defaultLinkModal = USER_MENU[0].children[0].children[0].screens[0].link;
                            $state.go(defaultLinkModal);
                            workspaceFactory.setActivity(USER_MENU[0].children[0].children[0],true,null);
                            
                        } else if(USER_MENU.callSuccess == "0") {
                            $state.go("no-modules");
                        }
                      }, 1000);
            });
        }

        console.log("///////////////////////////////// USER_SETTINGS  ///////////////////////////////////");
        console.log(USER_SETTINGS);

        vm.clients = GlobalService.getClients();
        vm.client = GlobalService.getClient();
        vm.clientName = vm.client.client_name;

        $rootScope.$on('gtw.nav.refreshclients', function (data) {
            //author asyed037
            settingFactory.getSettings().then(function (newUserSettings) {
                USER_SETTINGS.user.clients = newUserSettings.user.clients;
                vm.clients = GlobalService.getClients();
            })
        })

        $scope.changeClient = function (client) {
            GlobalService.setClient(client.client_key).then(function (data) {
                if (data.callSuccess === "1") {
                    // document.location.href="/";
                    $rootScope.donotLogoutUser =true; // shouldn't logout on reload flag used in GlobalController
                    location.reload();
                } else {
                    var switchToClient = _.filter(vm.clients, {
                        client_key: data.switch_to_client_key
                    });
                    if (null != switchToClient && switchToClient.length > 0) {
                        vm.clientName = switchToClient[0].client_name;
                    } else {
                        vm.clientName = "";
                    }
                    failedToChangeClient();
                }
            });
        };

        $scope.failedToChangeClient = function () {
            console.log("Failed to Change Client");
        }

        function failedToChangeClient() {
            $timeout(function () {
                var el = document.getElementById('change_client_failed');
                angular.element(el).triggerHandler('click');
            }, 0);
        }

        $scope.globalParams = GlobalService.globalParams;

    } //////////// END DIRECTIVE

    function UserNotifController(notificationService, USER_SETTINGS, $scope) {
        var vm = this;
        vm.loading = false;
        vm.notifications = [];
        vm.unReadCount = 0;

        ///////
        vm.markAsRead = markAsRead;
        getNotificatons();

        $scope.$on('refreshNotifications', function (event) {
            getNotificatons();
        })
        $scope.$on('pushNotifications', function (event, data) {
            vm.notifications.unshift({
                user_Notification_key: data.notificationKey,
                user_Notification_Message: data.notificationMessage,
                has_read: 0,
                type: data.type
            });
            vm.unReadCount++;
        })

        function getNotificatons() {
            vm.loading = true;
            notificationService.getNotifications(USER_SETTINGS.user.sso_id).then(function (data) {
                vm.loading = false;
                if (data.callSuccess === "1") {
                    vm.notifications = data.notifications;
                    vm.unReadCount = vm.notifications.filter(function (item) {
                        return !item.has_read;
                    }).length;
                }
            })
        }

        function markAsRead() {
            if (vm.unReadCount > 0) {
                var unReadNotifs = vm.notifications.filter(function (item) {
                    return !item.has_read;
                });

                var notificationKeys = unReadNotifs.map(function (item) {
                    return item.user_Notification_key;
                }).join();

                vm.loading = true;
                notificationService.markUserNotificationsAsRead(notificationKeys).then(function (data) {
                    if (data.callSuccess === "1") {
                        getNotificatons();
                    } else {
                        vm.loading = false;
                    }
                })
            }
        }
    }

    return controllers;

});
